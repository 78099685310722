<template>
  <!--
    A component that executes device-status-commands
  -->
  <Portlet
    :title="$t('status')"
    class="historyDialogStatus"
    icon="thermometer-half"
  >
    <template slot="buttons">
      <div>
        <span
          v-if="lastRequest"
          class="d-none d-md-inline-block mt-3 mr-3"
        >{{ $t('lastRequest') }}: {{ lastRequest | dateTime_dateTime }}</span>
        <button
          class="btn btn-sm btn-primary float-right"
          @click="lastRequest = Date.now(); getHistoryData()"
        >
          <font-awesome-icon
            class="mr-1"
            icon="sync-alt"
          />
          <span>{{ $t('getStatus') }}</span>
        </button>
      </div>
    </template>

    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <div class="clearfix" />
      <template v-if="histroyItems">
        <div
          class="t-container"
          style="margin: auto; position:relative;"
        >
          <img
            v-if="singleSide"
            style="height: 100%; margin-left:25%"
            src="@/assets/icons/other/automat-outlines-single.png"
          >
          <img
            v-else
            style="height: 100%; width: 100%;"
            src="@/assets/icons/other/automat-outlines.png"
          >
          <div
            v-for="(item, index) in histroyItems"
            :key="`row${ index }`"
          >
            <template v-if="item.key.includes('ticketpickup.cards.filllevel')">
              <div class="c-filllevel floating-element">
                <p style="margin-bottom: 0px; font-size: 11px; margin-left: -17px;">
                  RFID Tickets
                </p>
                <div class="battery-bar small-bar">
                  <div
                    v-for="index in 3"
                    :key="'cardBar' + index"
                    :class="['bar', { 'red' : item.value == '0' ? index < 2 : false}, { 'orange' : item.value == '1' ? index < 3 : false}, { 'green' : item.value == '2' ? index < 4 : false}]"
                  />
                </div>
              </div>
            </template>
            <template v-else-if="item.key.includes('terminal.printer.paper.filllevel')">
              <div :class="['floating-element', { 'p-filllevel-sng' : singleSide, 'p-filllevel': !singleSide }]">
                <p style="margin-bottom: 0px; font-size: 11px; margin-left: -26px;">
                  Thermal Paper
                </p>
                <div class="battery-bar small-bar">
                  <div
                    v-for="index in 3"
                    :key="'cardBar' + index"
                    :class="['bar', { 'red' : item.value == '0' ? index < 2 : false}, { 'orange' : item.value == '1' ? index < 3 : false}, { 'green' : item.value == '2' ? index < 4 : false}]"
                  />
                </div>
              </div>
            </template>
            <template
              v-else-if="item.key.includes('ticketpickup.cardprinter.tickets.produced_since_last_cleaning')"
            >
              <div class="last-cleaning floating-element">
                <span>{{ item.value }}</span>
                <p
                  style="margin-bottom: 0px; font-size: 11px; margin-left: -77px;"
                >
                  RFID Cards Printed since last clean
                </p>
              </div>
            </template>
            <template v-else-if="item.key.includes('terminal.P11')">
              <font-awesome-icon
                v-if="item.value < 1"
                :class="['floating-element', { 'terminal-input-door-sng' : singleSide, 'terminal-input-door': !singleSide }]"
                icon="door-open"
                style="height: 24px; width: 24px; color: red;"
              />
              <font-awesome-icon
                v-else
                :class="['floating-element', { 'terminal-input-door-sng' : singleSide, 'terminal-input-door': !singleSide }]"
                icon="door-closed"
                style="height: 24px; width: 24px; color: green;"
              />
            </template>
            <span
              v-else-if="item.key.includes('terminal.T0')"
              class="floating-element"
              :class="['floating-element', { 'terminal-temp-sng' : singleSide, 'terminal-temp': !singleSide }]"
            >{{ item.value.substring(0, 4) }} &#8451;</span>
            <span
              v-else-if="item.key.includes('terminal.T1')"
              class="floating-element"
              :class="['floating-element', { 'terminal-screen-temp-sng' : singleSide, 'terminal-screen-temp': !singleSide }]"
            >{{ item.value.substring(0, 4) }} &#8451;</span>
            <span
              v-else-if="item.key.includes('ticketpickup.T0')"
              class="pickup-temp floating-element"
            >{{ item.value.substring(0, 4) }} &#8451;</span>
            <span
              v-else-if="item.key.includes('ticketpickup.T1')"
              class="pickup-card-temp floating-element"
            >{{ item.value.substring(0, 4) }} &#8451;</span>
            <template v-else-if="item.key.includes('ticketpickup.P11')">
              <font-awesome-icon
                v-if="item.value < 1"
                class="pickup-main-door floating-element"
                icon="door-open"
                style="height: 24px; width: 24px; color: red;"
              />
              <font-awesome-icon
                v-else
                class="pickup-main-door floating-element"
                icon="door-closed"
                style="height: 24px; width: 24px; color: green;"
              />
            </template>
            <template v-else-if="item.key.includes('ticketpickup.P12') && !isPhotoprint">
              <font-awesome-icon
                v-if="item.value > 0"
                class="pickup-side-door floating-element"
                icon="door-open"
                style="height: 24px; width: 24px; color: red;"
              />
              <font-awesome-icon
                v-else
                class="pickup-side-door floating-element"
                icon="door-closed"
                style="height: 24px; width: 24px; color: green;"
              />
            </template>
            <template v-if="item.key.includes('advanced')">
              <div class="advanced-reading floating-element">
                <p
                  class="ml-1"
                  style="margin-bottom: 0px; font-size: 11px;"
                >
                  Advanced Feedings
                </p>
                <div class="battery-bar">
                  <div
                    v-for="index in 10"
                    :key="'cardBar' + index"
                    :class="['bar', { 'green' : item.value <= '20' ? index * 10 <= item.value : false}, { 'orange' : item.value > '20' ? index * 10 <= item.value : false}]"
                  />
                </div>
              </div>
            </template>
            <template v-else-if="item.key.includes('ticketpickup.TP0')">
              <font-awesome-icon
                v-if="item.value > 0"
                class="pickup-heating-housing floating-element"
                icon="fire-alt"
                style="height: 24px; width: 24px; color: red;"
              />
              <font-awesome-icon
                v-else
                class="pickup-heating-housing floating-element"
                icon="fire-alt"
                style="height: 24px; width: 24px; color: lightgray;"
              />
            </template>
            <template v-else-if="item.key.includes('ticketpickup.TP1')">
              <font-awesome-icon
                v-if="item.value > 0"
                class="pickup-heating-printer floating-element"
                icon="fire-alt"
                style="height: 24px; width: 24px; color: red;"
              />
              <font-awesome-icon
                v-else
                class="pickup-heating-printer floating-element"
                icon="fire-alt"
                style="height: 24px; width: 24px; color: lightgray;"
              />
            </template>
            <template v-else-if="item.key.includes('ticketpickup.TP2')">
              <font-awesome-icon
                v-if="item.value > 0"
                class="pickup-fan-housing floating-element"
                icon="fan"
                style="height: 24px; width: 24px; color: blue;"
              />
              <font-awesome-icon
                v-else
                class="pickup-fan-housing floating-element"
                icon="fan"
                style="height: 24px; width: 24px; color: lightgray;"
              />
            </template>
            <template v-else-if="item.key.includes('terminal.TP0')">
              <font-awesome-icon
                v-if="item.value > 0"
                :class="['floating-element', { 'terminal-heating-housing-sng' : singleSide, 'terminal-heating-housing': !singleSide }]"
                icon="fire-alt"
                style="height: 24px; width: 24px; color: red;"
              />
              <font-awesome-icon
                v-else
                :class="['floating-element', { 'terminal-heating-housing-sng' : singleSide, 'terminal-heating-housing': !singleSide }]"
                icon="fire-alt"
                style="height: 24px; width: 24px; color: lightgray;"
              />
            </template>
            <template v-else-if="item.key.includes('camera.status')">
              <font-awesome-icon
                v-if="item.value > 0"
                class="camera-status floating-element"
                icon="camera"
                style="height: 24px; width: 24px; color: red;"
              />
              <font-awesome-icon
                v-else
                class="camera-status floating-element"
                icon="camera"
                style="height: 24px; width: 24px; color: green;"
              />
            </template>
            <template v-else-if="item.key.includes('terminal.TP2')">
              <font-awesome-icon
                v-if="item.value > 0"
                :class="['floating-element', { 'terminal-fan-housing-sng' : singleSide, 'terminal-fan-housing': !singleSide }]"
                icon="fan"
                style="height: 24px; width: 24px; color: blue;"
              />
              <font-awesome-icon
                v-else
                :class="['floating-element', { 'terminal-fan-housing-sng' : singleSide, 'terminal-fan-housing': !singleSide }]"
                icon="fan"
                style="height: 24px; width: 24px; color: lightgray;"
              />
            </template>
            <template v-else-if="item.key.includes('terminal.TP3')">
              <font-awesome-icon
                v-if="item.value > 0"
                :class="['floating-element', { 'terminal-fan-display-sng' : singleSide, 'terminal-fan-display': !singleSide }]"
                icon="fan"
                style="height: 24px; width: 24px; color: blue;"
              />
              <font-awesome-icon
                v-else
                :class="['floating-element', { 'terminal-fan-display-sng' : singleSide, 'terminal-fan-display': !singleSide }]"
                icon="fan"
                style="height: 24px; width: 24px; color: lightgray;"
              />
            </template>
            <template v-else-if="item.key.includes('ticketpickup.cardprinter.ready')">
              <font-awesome-icon
                v-if="item.value > 0"
                class="pickup-printer-ready floating-element"
                icon="print"
                style="height: 24px; width: 24px; color: green;"
              />
              <font-awesome-icon
                v-else
                class="pickup-printer-ready floating-element"
                icon="print"
                style="height: 24px; width: 24px; color: red;"
              />
            </template>
            <template v-else-if="item.key.includes('photopickup.printer.error')">
              <font-awesome-icon
                v-if="item.value < 1"
                class="photopickup-printer floating-element"
                icon="print"
                style="height: 24px; width: 24px; color: green;"
              />
              <font-awesome-icon
                v-else
                class="photopickup-printer floating-element"
                icon="print"
                style="height: 24px; width: 24px; color: red;"
              />
            </template>
            <template v-else-if="item.key.includes('terminal.printer.ready')">
              <font-awesome-icon
                v-if="item.value > 0"
                :class="['floating-element', { 'terminal-printer-ready-sng' : singleSide, 'terminal-printer-ready': !singleSide }]"
                icon="print"
                style="height: 24px; width: 24px; color: green;"
              />
              <font-awesome-icon
                v-else
                :class="['floating-element', { 'terminal-printer-ready-sng' : singleSide, 'terminal-printer-ready': !singleSide }]"
                icon="print"
                style="height: 24px; width: 24px; color: red;"
              />
            </template>
          </div>
          <template v-if="percentage != -10">
            <div class="photo-filllevel floating-element">
              <p style="margin-bottom: 0px; font-size: 11px;">
                Photos
              </p>
              <div
                v-tooltip="printerRemainingQuantity().value + '/' + printerInitialQuantity().value"
                class="battery-bar"
              >
                <div
                  v-for="index in 10"
                  :key="'photosBar' + index"
                  :class="['bar', { 'red' : percentage >= 0 && percentage < 30 ? index < 3 : false}, { 'orange' : percentage >= 30 && percentage < 60 ? index < 7 : false}, { 'green' : percentage >= 60 ? index < 11 : false}]"
                />
              </div>
            </div>
          </template>
        </div>
      </template>
      <p v-else>
        {{ $t('noDataAvailable') }}
      </p>
    </template>
  </Portlet>
</template>

<script>
import { dateTimeMixin } from "@/mixins/dateTimeMixin.js";
import { authenticationMixin } from "@/mixins/authenticationMixin";

export default {
  name: "HistoryDialogStatus",
  mixins: [dateTimeMixin, authenticationMixin],
  props: {
    uuid: {
      type: String,
      required: true
    },
    isPhotoprint: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    singleSide: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    }
  },
  data () {
    return {
      histroyItems: null,
      loading: true,
      lastRequest: null,
      percentage: 0
    };
  },
  created () {
    this.getHistoryData();
  },
  methods: {
    printerInitialQuantity () {
      if (this.histroyItems) {
        return this.histroyItems.find(
          x => x.key == "photopickup.printer.initial_print_quantity"
        );
      }
      return null;
    },
    printerRemainingQuantity () {
      if (this.histroyItems) {
        return this.histroyItems.find(
          x => x.key == "photopickup.printer.remaining_print_quantity"
        );
      }
      return null;
    },
    calculatePercentage () {
      return ((parseInt(this.printerRemainingQuantity().value) * 100) / parseInt(this.printerInitialQuantity().value));
    },
    getHistoryData () {
      this.loading = true;
      this.axios
        .get(`/MonitoringSystem/GetDeviceItemHistory?uuid=${this.uuid}`)
        .then(response => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.histroyItems = response.data;
          if (
            this.printerInitialQuantity() &&
            this.printerRemainingQuantity()
          ) {
            this.percentage = this.calculatePercentage();
          } else {
            this.percentage = -10;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
pre {
  margin: 0px;
}

.reloadButton i {
  font-size: 1.5rem;
}

.battery-bar {
  position: relative;
  border: 1px solid black;
  width: 145px;
  display: flex;
  padding: 2px;
}

.battery-bar.small-bar {
  width: 46px;
}

.bar {
  width: 0;
  border: solid thin black;
  margin-right: 2px;
  padding: 5px;
  height: 15px;
  background: transparent;
}

.red {
  background: red;
  border: solid thin rgba(255, 255, 255, 0.1);
}

.green {
  background: green;
  border: solid thin rgba(255, 255, 255, 0.1);
}

.orange {
  background: orange;
  border: solid thin rgba(255, 255, 255, 0.1);
}

.t-container {
  height: 350px;
  width: 260px;
}

@media (min-width: 600px) {
  .t-container {
    height: 350px;
    width: 350px;
  }
  .floating-element.pickup-side-door {
    top: 46% !important;
    right: 0% !important;
  }
  .floating-element.p-filllevel {
    bottom: 33% !important;
    left: 29.5% !important;
  }
  .floating-element.p-filllevel p {
    font-size: 13px !important;
    margin-left: -40px !important;
  }
  .floating-element.c-filllevel {
    bottom: 33% !important;
    right: 11%;
  }
  .floating-element.c-filllevel p {
    font-size: 13px !important;
    margin-left: -28px !important;
  }
  .floating-element.last-cleaning {
    bottom: 12% !important;
    left: 62.5% !important;
  }
  .floating-element.last-cleaning span {
    font-size: 20px !important;
  }
  .floating-element.last-cleaning p {
    margin-bottom: -3px !important;
    font-size: 13px !important;
    margin-left: -77px !important;
  }
  .floating-element.advanced-reading p {
    font-size: 13px !important;
  }
  .floating-element.c-filllevel p {
    font-size: 13px !important;
  }
  .floating-element.terminal-temp {
    left: 31% !important;
  }
  .floating-element.terminal-temp-sng {
    top: 13% !important;
    left: 58% !important;
  }
  .floating-element.advanced-reading {
    bottom: 5% !important;
  }
  .floating-element.pickup-heating-housing {
    bottom: 25% !important;
    right: 41% !important;
  }
  .floating-element.pickup-heating-printer {
    bottom: 25% !important;
    right: 8% !important;
  }
  .floating-element.pickup-fan-housing {
    top: 14% !important;
    right: 26% !important;
  }
  .floating-element.terminal-heating-housing {
    bottom: 25% !important;
    left: 8% !important;
  }
  .floating-element.terminal-fan-housing {
    top: 14% !important;
    left: 30% !important;
  }
  .floating-element.terminal-fan-display {
    top: 40% !important;
    left: 26% !important;
  }
 .floating-element.photo-filllevel {
    bottom: 33% !important;
    right: 6% !important;
  }
  .floating-element.photo-filllevel p {
    font-size: 13px !important;
    margin-left: 0px !important;
  }
  .floating-element.pickup-printer-ready {
    bottom: 32.5% !important;
    right: 25% !important;
  }
  .floating-element.photopickup-printer {
    bottom: 25.5%;
    right: 29.5%;
  }
  .floating-element.terminal-printer-ready {
    bottom: 32.5% !important;
    left: 22% !important;
  }
  .floating-element.camera-status {
    top: -3% !important;
    left: 50% !important;
  }
}

@media (min-width: 990px) {
  .t-container {
    height: 300px;
    width: 300px;
  }
  .floating-element.pickup-side-door {
    top: 46% !important;
    right: -2% !important;
  }
  .floating-element.p-filllevel {
    bottom: 33% !important;
    left: 27% !important;
  }
  .floating-element.p-filllevel p {
    font-size: 13px !important;
    margin-left: -40px !important;
  }
  .floating-element.c-filllevel {
    bottom: 33% !important;
    right: 11%;
  }
  .floating-element.c-filllevel p {
    font-size: 13px !important;
    margin-left: -28px !important;
  }
  .floating-element.last-cleaning {
    bottom: 4% !important;
    left: 62.5% !important;
  }
  .floating-element.last-cleaning span {
    font-size: 20px !important;
  }
  .floating-element.last-cleaning p {
    margin-bottom: -3px !important;
    font-size: 13px !important;
    margin-left: -77px !important;
  }
  .floating-element.advanced-reading p {
    font-size: 13px !important;
  }
  .floating-element.c-filllevel p {
    font-size: 13px !important;
  }
  .floating-element.terminal-temp {
    left: 29% !important;
  }
  .floating-element.terminal-temp-sng {
    top: 13% !important;
    left: 55% !important;
  }
  .floating-element.advanced-reading {
    bottom: 0% !important;
  }
  .floating-element.pickup-heating-housing {
    bottom: 25% !important;
    right: 40% !important;
  }
  .floating-element.pickup-heating-printer {
    bottom: 25% !important;
    right: 8% !important;
  }
  .floating-element.pickup-fan-housing {
    top: 14% !important;
    right: 26% !important;
  }
  .floating-element.terminal-heating-housing {
    bottom: 25% !important;
    left: 7% !important;
  }
  .floating-element.terminal-fan-housing {
    top: 14% !important;
    left: 30% !important;
  }
  .floating-element.terminal-fan-display {
    top: 40% !important;
    left: 26% !important;
  }
 .floating-element.photo-filllevel {
    bottom: 33% !important;
    right: 1% !important;
  }
  .floating-element.photo-filllevel p {
    font-size: 13px !important;
    margin-left: 0px !important;
  }
  .floating-element.pickup-printer-ready {
    bottom: 32.5% !important;
    right: 27% !important;
  }
  .floating-element.photopickup-printer {
    bottom: 25.5%;
    right: 29.5%;
  }
  .floating-element.terminal-printer-ready {
    bottom: 32.5% !important;
    left: 18% !important;
  }
  .floating-element.camera-status {
    top: -4% !important;
    left: 50% !important;
  }
}

@media (min-width: 1200px) {
  .t-container {
    height: 400px;
    width: 400px;
  }
  .floating-element.pickup-side-door {
    top: 46% !important;
    right: 0% !important;
  }
  .floating-element.p-filllevel {
    bottom: 32% !important;
    left: 30% !important;
  }
  .floating-element.p-filllevel p {
    font-size: 13px !important;
    margin-left: -40px !important;
  }
  .floating-element.c-filllevel {
    bottom: 32% !important;
    right: 11%;
  }
  .floating-element.c-filllevel p {
    font-size: 13px !important;
    margin-left: -28px !important;
  }
  .floating-element.last-cleaning {
    bottom: 11% !important;
    left: 64% !important;
  }
  .floating-element.last-cleaning span {
    font-size: 20px !important;
  }
  .floating-element.last-cleaning p {
    margin-bottom: -3px !important;
    font-size: 13px !important;
    margin-left: -77px !important;
  }
  .floating-element.c-filllevel p {
    font-size: 13px !important;
  }
  .floating-element.advanced-reading p {
    font-size: 13px !important;
  }
  .floating-element.terminal-temp {
    left: 31% !important;
  }
  .floating-element.terminal-temp-sng {
    top: 13% !important;
    left: 60% !important;
  }
  .floating-element.advanced-reading {
    left: 0% !important;
    bottom: 3% !important;
  }
  .floating-element.pickup-heating-housing {
    bottom: 25% !important;
    right: 41% !important;
  }
  .floating-element.pickup-heating-printer {
    bottom: 25% !important;
    right: 8% !important;
  }
  .floating-element.pickup-fan-housing {
    top: 14% !important;
    right: 26% !important;
  }
  .floating-element.terminal-heating-housing {
    bottom: 25% !important;
    left: 8% !important;
  }
  .floating-element.terminal-fan-housing {
    top: 14% !important;
    left: 30% !important;
  }
  .floating-element.terminal-fan-display {
    top: 40% !important;
    left: 26% !important;
  }
  .floating-element.photo-filllevel {
    bottom: 32% !important;
    right: 9% !important;
  }
  .floating-element.photo-filllevel p {
    font-size: 13px !important;
    margin-left: 0px !important;
  }
  .floating-element.pickup-printer-ready {
    bottom: 31.5% !important;
    right: 23.5% !important;
  }
  .floating-element.photopickup-printer {
    bottom: 25.5%;
    right: 29.5%;
  }
  .floating-element.terminal-printer-ready {
    bottom: 31.5% !important;
    left: 23% !important;
  }
  .floating-element.camera-status {
    top: -3% !important;
    left: 50% !important;
  }
}

@media (min-width: 1350px) {
  .t-container {
    height: 300px;
    width: 300px;
  }
  .floating-element.pickup-side-door {
    top: 46% !important;
    right: -2% !important;
  }
  .floating-element.p-filllevel {
    bottom: 33% !important;
    left: 27% !important;
  }
  .floating-element.p-filllevel p {
    font-size: 13px !important;
    margin-left: -40px !important;
  }
  .floating-element.c-filllevel {
    bottom: 33% !important;
    right: 11%;
  }
  .floating-element.c-filllevel p {
    font-size: 13px !important;
    margin-left: -28px !important;
  }
  .floating-element.last-cleaning {
    bottom: 4% !important;
    left: 62.5% !important;
  }
  .floating-element.last-cleaning span {
    font-size: 20px !important;
  }
  .floating-element.last-cleaning p {
    margin-bottom: -3px !important;
    font-size: 13px !important;
    margin-left: -77px !important;
  }
  .floating-element.advanced-reading p {
    font-size: 13px !important;
  }
  .floating-element.c-filllevel p {
    font-size: 13px !important;
  }
  .floating-element.terminal-temp {
    left: 29% !important;
  }
  .floating-element.terminal-temp-sng {
    top: 13% !important;
    left: 55% !important;
  }
  .floating-element.advanced-reading {
    bottom: 0% !important;
  }
  .floating-element.pickup-heating-housing {
    bottom: 25% !important;
    right: 40% !important;
  }
  .floating-element.pickup-heating-printer {
    bottom: 25% !important;
    right: 8% !important;
  }
  .floating-element.pickup-fan-housing {
    top: 14% !important;
    right: 26% !important;
  }
  .floating-element.terminal-heating-housing {
    bottom: 25% !important;
    left: 7% !important;
  }
  .floating-element.terminal-fan-housing {
    top: 14% !important;
    left: 30% !important;
  }
  .floating-element.terminal-fan-display {
    top: 40% !important;
    left: 26% !important;
  }
 .floating-element.photo-filllevel {
    bottom: 33% !important;
    right: 2% !important;
  }
  .floating-element.photo-filllevel p {
    font-size: 13px !important;
    margin-left: 0px !important;
  }
  .floating-element.pickup-printer-ready {
    bottom: 32.5% !important;
    right: 27% !important;
  }
  .floating-element.photopickup-printer {
    bottom: 25.5%;
    right: 29.5%;
  }
  .floating-element.terminal-printer-ready {
    bottom: 32.5% !important;
    left: 18% !important;
  }
  .floating-element.camera-status {
    top: -4% !important;
    left: 50% !important;
  }
}

@media (min-width: 1450px) {
  .t-container {
    height: 350px;
    width: 350px;
  }
  .floating-element.pickup-side-door {
    top: 46% !important;
    right: 0% !important;
  }
  .floating-element.p-filllevel {
    bottom: 33% !important;
    left: 29.5% !important;
  }
  .floating-element.p-filllevel p {
    font-size: 13px !important;
    margin-left: -40px !important;
  }
  .floating-element.c-filllevel {
    bottom: 33% !important;
    right: 11%;
  }
  .floating-element.c-filllevel p {
    font-size: 13px !important;
    margin-left: -28px !important;
  }
  .floating-element.last-cleaning {
    bottom: 12% !important;
    left: 62.5% !important;
  }
  .floating-element.last-cleaning span {
    font-size: 20px !important;
  }
  .floating-element.last-cleaning p {
    margin-bottom: -3px !important;
    font-size: 13px !important;
    margin-left: -77px !important;
  }
  .floating-element.advanced-reading p {
    font-size: 13px !important;
  }
  .floating-element.c-filllevel p {
    font-size: 13px !important;
  }
  .floating-element.terminal-temp {
    left: 31% !important;
  }
  .floating-element.terminal-temp-sng {
    top: 13% !important;
    left: 58% !important;
  }
  .floating-element.advanced-reading {
    bottom: 5% !important;
  }
  .floating-element.pickup-heating-housing {
    bottom: 25% !important;
    right: 41% !important;
  }
  .floating-element.pickup-heating-printer {
    bottom: 25% !important;
    right: 8% !important;
  }
  .floating-element.pickup-fan-housing {
    top: 14% !important;
    right: 26% !important;
  }
  .floating-element.terminal-heating-housing {
    bottom: 25% !important;
    left: 8% !important;
  }
  .floating-element.terminal-fan-housing {
    top: 14% !important;
    left: 30% !important;
  }
  .floating-element.terminal-fan-display {
    top: 40% !important;
    left: 26% !important;
  }
 .floating-element.photo-filllevel {
    bottom: 33% !important;
    right: 6% !important;
  }
  .floating-element.photo-filllevel p {
    font-size: 13px !important;
    margin-left: 0px !important;
  }
  .floating-element.pickup-printer-ready {
    bottom: 32.5% !important;
    right: 25% !important;
  }
  .floating-element.photopickup-printer {
    bottom: 25.5%;
    right: 29.5%;
  }
  .floating-element.terminal-printer-ready {
    bottom: 32.5% !important;
    left: 22% !important;
  }
  .floating-element.camera-status {
    top: -4% !important;
    left: 50% !important;
  }
}

@media (min-width: 1600px) {
  .t-container {
    height: 400px;
    width: 400px;
  }
  .floating-element.pickup-side-door {
    top: 46% !important;
    right: 0% !important;
  }
  .floating-element.p-filllevel {
    bottom: 33% !important;
    left: 31.5% !important;
  }
  .floating-element.p-filllevel p {
    font-size: 13px !important;
    margin-left: -40px !important;
  }
  .floating-element.c-filllevel {
    bottom: 33% !important;
    right: 11%;
  }
  .floating-element.c-filllevel p {
    font-size: 13px !important;
    margin-left: -28px !important;
  }
  .floating-element.last-cleaning {
    bottom: 12% !important;
    left: 62.5% !important;
  }
  .floating-element.last-cleaning span {
    font-size: 20px !important;
  }
  .floating-element.last-cleaning p {
    margin-bottom: -3px !important;
    font-size: 13px !important;
    margin-left: -77px !important;
  }
  .floating-element.advanced-reading p {
    font-size: 13px !important;
  }
  .floating-element.c-filllevel p {
    font-size: 13px !important;
  }
  .floating-element.terminal-temp {
    left: 33% !important;
  }
  .floating-element.terminal-temp-sng {
    top: 13% !important;
    left: 60% !important;
  }
  .floating-element.advanced-reading {
    bottom: 5% !important;
  }
  .floating-element.pickup-heating-housing {
    bottom: 25% !important;
    right: 41% !important;
  }
  .floating-element.pickup-heating-printer {
    bottom: 25% !important;
    right: 8% !important;
  }
  .floating-element.pickup-fan-housing {
    top: 14% !important;
    right: 26% !important;
  }
  .floating-element.terminal-heating-housing {
    bottom: 25% !important;
    left: 8% !important;
  }
  .floating-element.terminal-fan-housing {
    top: 14% !important;
    left: 30% !important;
  }
  .floating-element.terminal-fan-display {
    top: 40% !important;
    left: 26% !important;
  }
 .floating-element.photo-filllevel {
    bottom: 33% !important;
    right: 10% !important;
  }
  .floating-element.photo-filllevel p {
    font-size: 13px !important;
    margin-left: 0px !important;
  }
  .floating-element.pickup-printer-ready {
    bottom: 32.5% !important;
    right: 23% !important;
  }
  .floating-element.photopickup-printer {
    bottom: 25.5%;
    right: 29.5%;
  }
  .floating-element.terminal-printer-ready {
    bottom: 32.5% !important;
    left: 25% !important;
  }
  .floating-element.camera-status {
    top: -2% !important;
    left: 50% !important;
  }
}

@media (min-width: 1750px) {
  .floating-element.pickup-card-temp {
    bottom: 25%;
    right: 18%;
  }
  .t-container {
    height: 450px;
    width: 450px;
  }
  .floating-element.pickup-side-door {
    top: 46% !important;
    right: 1% !important;
  }
  .floating-element.p-filllevel {
    bottom: 33% !important;
    left: 32.5% !important;
  }
  .floating-element.p-filllevel p {
    font-size: 13px !important;
    margin-left: -40px !important;
  }
  .floating-element.c-filllevel {
    bottom: 33% !important;
    right: 11%;
  }
  .floating-element.c-filllevel p {
    font-size: 13px !important;
    margin-left: -28px !important;
  }
  .floating-element.last-cleaning {
    bottom: 12% !important;
    left: 62.5% !important;
  }
  .floating-element.last-cleaning span {
    font-size: 20px !important;
  }
  .floating-element.last-cleaning p {
    margin-bottom: -3px !important;
    font-size: 13px !important;
    margin-left: -77px !important;
  }
  .floating-element.advanced-reading p {
    font-size: 13px !important;
  }
  .floating-element.c-filllevel p {
    font-size: 13px !important;
  }
  .floating-element.terminal-temp {
    left: 34% !important;
  }
  .floating-element.terminal-temp-sng {
    top: 13% !important;
    left: 60% !important;
  }
  .floating-element.advanced-reading {
    bottom: 5% !important;
  }
  .floating-element.pickup-heating-housing {
    bottom: 25% !important;
    right: 42% !important;
  }
  .floating-element.pickup-heating-printer {
    bottom: 25% !important;
    right: 8% !important;
  }
  .floating-element.pickup-fan-housing {
    top: 14.5% !important;
    right: 26% !important;
  }
  .floating-element.terminal-heating-housing {
    bottom: 25% !important;
    left: 8% !important;
  }
  .floating-element.terminal-fan-housing {
    top: 14.5% !important;
    left: 30% !important;
  }
  .floating-element.terminal-fan-display {
    top: 40% !important;
    left: 26% !important;
  }
 .floating-element.photo-filllevel {
    bottom: 33% !important;
    right: 10% !important;
  }
  .floating-element.photo-filllevel p {
    font-size: 13px !important;
    margin-left: 0px !important;
  }
  .floating-element.pickup-printer-ready {
    bottom: 32.5% !important;
    right: 22% !important;
  }
  .floating-element.photopickup-printer {
    bottom: 25.5%;
    right: 29.5%;
  }
  .floating-element.terminal-printer-ready {
    bottom: 32.5% !important;
    left: 26.5% !important;
  }
  .floating-element.camera-status {
    top: -2% !important;
    left: 50% !important;
  }
  .floating-element.terminal-printer-ready-sng {
    bottom: 25% !important;
    right: 42% !important;
  }
}

.floating-element {
  position: absolute;
}

.floating-element.terminal-temp {
  top: 21%;
  left: 26%;
}
.floating-element.terminal-temp-sng {
  top: 13%;
  left: 60%;
}
.floating-element.terminal-screen-temp-sng {
  top: 35%;
  left: 50%;
}
.floating-element.terminal-screen-temp {
  top: 35%;
  left: 25%;
}
.floating-element.pickup-temp {
  top: 21%;
  right: 11%;
}
.floating-element.c-filllevel {
  bottom: 36%;
  right: 11%;
}
.floating-element.p-filllevel-sng {
  bottom: 25%;
  right: 30%;
}
.floating-element.p-filllevel {
  bottom: 36%;
  left: 24%;
}
.floating-element.last-cleaning {
  bottom: 6%;
  left: 64%;
}
.floating-element.pickup-main-door {
  top: 14%;
  right: 8%;
}
.floating-element.pickup-side-door {
  top: 46%;
  right: -3%;
}
.floating-element.terminal-input-door {
  top: 14%;
  left: 8%;
}
.floating-element.terminal-input-door-sng {
  top: 5%;
  left: 29%;
}
.floating-element.advanced-reading {
  bottom: -2%;
  left: -5%;
}
.floating-element.pickup-heating-housing {
  bottom: 25%;
  right: 38%;
}
.floating-element.pickup-heating-printer {
  bottom: 25%;
  right: 8%;
}
.floating-element.pickup-fan-housing {
  top: 13%;
  right: 26%;
}
.floating-element.terminal-heating-housing {
  bottom: 25%;
  left: 8%;
}
.floating-element.terminal-heating-housing-sng {
  bottom: 18%;
  left: 28%;
}
.floating-element.terminal-fan-housing {
  top: 13%;
  left: 30%;
}
.floating-element.terminal-fan-housing-sng {
  top: 5%;
  left: 55%;
}
.floating-element.terminal-fan-display {
  top: 40%;
  left: 24%;
}
.floating-element.terminal-fan-display-sng {
  top: 40%;
  left: 50%;
}
.floating-element.camera-status {
  top: -2%;
  left: 50%;
}
.floating-element.photo-filllevel {
  bottom: 36%;
  right: -1%;
}
.floating-element.photo-filllevel p {
    margin-left: 20px;
  }
.floating-element.pickup-printer-ready {
  bottom: 35.5%;
  right: 29.5%;
}
.floating-element.photopickup-printer {
  bottom: 25.5%;
  right: 29.5%;
}
.floating-element.terminal-printer-ready {
  bottom: 35.5%;
  left: 14%;
}
.floating-element.terminal-printer-ready-sng {
  bottom: 25%;
  right: 45%;
}
.floating-element.pickup-card-temp {
  bottom: 25%;
  right: 18%;
}
</style>
